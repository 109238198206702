/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { actions as peopleFollowActions } from 'Slice/PeopleFollowSlice';
import { selectFollowFailed, selectFollowLoading } from 'Selector/PeopleFollowSelector';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { UpdatePersonProfileFollow } from 'Types/RequestBodies';
import {
  Link,
  useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { PersonalityTypes } from 'Types/PersonTypes';
import { ONBOARDING_ROUTE, PERSONALITY_INSIGHTS_ROUTE, SEARCH } from 'Utils/Constants';

import { actions as certificationActions } from 'Slice/CertificationSlice';
import { selectData } from 'Selector/CertificationSelector';
import { getCurrentTime, isOnboardingRoute, valueToObject } from 'Utils/UtilityFunctions';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { Tooltip } from 'antd';
import { actions as personActions } from 'Slice/PersonProfileSlice';
import PeopleWidget from './PeopleWidget';
import SmallButton from './SmallButton';
import {
  primaryFont, secondaryFont, wLight, primaryHeadingColor, wMLight, textBlack,
  blueCheckBoxStyle, maxWidth, retina, retinaOne, wMedium, maxTablet, maxMobile,
  minWidthApp,
  minExtraLargeScreen,
} from '../../../Utils/utilsStyle';

export type PersonProps = {
  persona?: string,
  logo?: string,
  personName?: string,
  tag?: string,
  follow?: boolean,
  noFollow?: boolean,
  personaType?: string,
  executiveType?: string,
  hidePersonality?: boolean,
  wrapperColor?: string,
  showRemoveIcon?: boolean,
  showCheckBox?: boolean,
  personId?: number,
  personality?: string,
  personAddedFlag?: boolean,
  diameter?: string,
  diameterSm?: string,
  diameterXxsm?: string,
  imageDiameter?: string,
  isBtnAdd?: boolean
  checked?: boolean,
  dragCheck?: boolean,
  company?: string,
  addRemovePerson?: (a: boolean, b: number) => void,
  openInNewTab?: boolean,
  disableNavigation?: boolean,
  tagTextPadding?: string,
  isBoxResOne?: boolean,
  personalityTypesColors?: PersonalityTypes[]
  isClassFromLargeCard?: boolean,
  holoDiameterHeight?: string,
  holoDiameterWidth?: string,
  holoDiameterXxsmHeight?: string,
  holoDiameterXxsmWidth?: string,
  showDrawerIcon?: boolean,
  groupTitle?: string,
  membersActive?: boolean,
  profileConfirmation?: boolean,
  isPersonPage?: boolean,
  isSearchPage?: boolean,
  isLivePerson?: boolean,
  isRecommended?: boolean,
  inGlobalSearch?: boolean,
} & typeof defaultProps;

const defaultProps = {
  membersActive: false,
  groupTitle: '',
  holoDiameterHeight: '155px',
  holoDiameterWidth: '168px',
  holoDiameterXxsmHeight: '114px',
  holoDiameterXxsmWidth: '124px',
  persona: '#979797',
  logo: 'https://picsum.photos/200',
  personName: 'Adam Richard Terrence Smith',
  tag: 'CXO',
  follow: true,
  noFollow: false,
  personaType: '',
  hidePersonality: true,
  wrapperColor: '#979797',
  showRemoveIcon: false,
  showCheckBox: false,
  checked: false,
  personId: 0,
  executiveType: 'EXECUTIVE',
  personality: 'EXECUTIVE',
  personAddedFlag: true,
  diameter: '175px',
  diameterSm: '175px',
  diameterXxsm: '130px',
  imageDiameter: '175px',
  isBtnAdd: false,
  dragCheck: false,
  company: '',
  addRemovePerson: (a: boolean, b: number) => { },
  openInNewTab: false,
  disableNavigation: false,
  tagTextPadding: '',
  isBoxResOne: false,
  isClassFromLargeCard: false,
  showDrawerIcon: false,
  personalityTypesColors: [{
    description: '',
    color: '',
    image: '',
    key: '',
    value: '',
    cardColor: '',
  }],
  profileConfirmation: false,
  isPersonPage: false,
  isSearchPage: false,
  isLivePerson: false,
  isRecommended: false,
  inGlobalSearch: false,
};

const Wrapper = styled.div<{ color: string, diameter: string, diameterSm: string, tagTextPadding: string }>`
  margin-bottom: 50px;
  position: relative;
  z-index: 0;
  &.searchPeopleCard {
    @media all and (max-width: ${maxMobile}) {
      margin-top: 0px;
    }
    .below-card-detail {
      margin-top: -10px;
      @media all and (max-width: ${minWidthApp}) {
        margin-top: 0px;
      }
    }
    p {
      min-height: 0px;
    }
    .person-name-here {
      color: ${textBlack};
      text-align: center;
      font-family: ${primaryFont};
      font-size: 16px;
      font-weight: ${wMLight};
      line-height: 18px;
      letter-spacing: -0.52px;
      margin-bottom: 4px;
      @media all and ( min-width: ${minExtraLargeScreen}) {
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.746px;
      }
      @media all and (max-width: ${maxWidth}) , ${retina} {
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.52px;
      }
      @media all and (max-width: ${maxTablet}) {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.414px;
        margin-bottom: 2px;
      }
    }
    .person-tag-here {
      color: ${primaryHeadingColor};
      text-align: center;
      font-family: ${primaryFont};
      font-weight: ${wMedium};
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.35px;
      margin-bottom: 4px;
      min-height: 0px;
      @media all and (min-width: ${minExtraLargeScreen}) {
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.503px;
      }
      @media all and (max-width: ${maxWidth}) , ${retina} {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.35px;
        min-height: 0px;
      }
      @media all and (max-width: ${maxTablet}) {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.279px;
      }
      &.triggers-bullet-tooltip {
        &.person-designation-tip {
          .ant-tooltip {
            .ant-tooltip-content {
              .ant-tooltip-inner {
                max-width: 150px;
                white-space: normal;
              }
            }
          }
        }
      }
    }
    .person-company-here {
      color: ${primaryHeadingColor};
      text-align: center;
      font-family: ${primaryFont};
      font-size: 14px;
      font-weight: ${wLight};
      line-height: 16px;
      letter-spacing: -0.35px;
      margin-bottom: 8px;
      min-height: 15px;
      @media all and (min-width: ${minExtraLargeScreen}) {
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.503px;
      }
      @media all and (max-width: ${maxWidth}) , ${retina} {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.35px;
      }
      @media all and (max-width: ${maxTablet}) {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.279px;
        margin-top: 2px !important;
        margin-bottom: 2px;
      }
      &.triggers-bullet-tooltip {
        &.person-designation-tip {
          .ant-tooltip {
            .ant-tooltip-content {
              .ant-tooltip-inner {
                max-width: 125px;
                white-space: normal;
              }
            }
          }
        }
      }
    }
    .company-name-two {
      margin-top: 0px;
      @media all and (max-width: ${maxTablet}) {
        font-size: 12px;
      }
    }
    &.btnAdd {
      .follow-c-btn {
        margin-bottom: 0px;
        .small-button-chinese {
          display: inline-flex;
          height: 24px;
          width: 84px;
          padding: 4px 14px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 16px;
          text-align: center;
          font-family: ${primaryFont};
          font-size: 14px;
          font-weight: ${wMLight};
          line-height: 17px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            height: 34px;
            width: 120px;
            font-size: 20px;
            line-height: 23px;
            border-radius: 22px;
          }
          @media all and (max-width: ${maxWidth}) , ${retina} {
            height: 24px;
            width: 84px;
            border-radius: 16px;
            font-size: 14px;
            line-height: 17px;
            padding: 4px 14px;
          }
          @media all and (max-width: ${maxTablet}) {
            height: 20px;
            width: 72px;
            padding: 3px 12px;
            border-radius: 16px;
            font-size: 12px;
            line-height: 15px;
          }
          @media all and (max-width: ${maxMobile}) {
            height: 20px;
            width: 64px;
            font-size: 12px;
            line-height: 15px;
            padding: 3px 12px;
          }
        }
      }
    }
    .isFromLargeCard {
      .name-wrapper {
        h4{
          padding: 0px 8px;
        }
        
        .anticon {
          display: none;
        }
      }
    }
  }
  @media all and (max-width: ${maxMobile}) {
    margin-bottom:25px;
    margin-top: 25px;
  }
  &.classFromLargeCard {
    .c-pointer  {
      .primary-color {
        margin-top: 0px;
      }  
    }
  }
  .person-name-here {
    font-size: 20px;
    color: ${primaryHeadingColor};
    font-weight: 800;
    font-family: ${secondaryFont};
    letter-spacing: -0.45px;
    line-height: 25px;
    margin-top: 0px;
    margin-bottom:5px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      margin-top: 0px;
      font-size: 14px;
      line-height: 17px;
    }
    @media all and (max-width: ${maxTablet}) {
      font-size: 12px;
      margin-bottom: 2px;
    }
  }
  p {
    font-size: 16px;
    color: #7E7E7E;
    font-weight: 400;
    line-height: 20px;
    min-height: 20px;
    font-family: ${primaryFont};
    letter-spacing: -0.75px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 12px;
      line-height: 14px;
      min-height: 14px;
      padding: ${(props) => props.tagTextPadding};
    }
  }
  .person-tag-here {
    min-height: 40px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      min-height: 28px;
    }
    @media all and (max-width: ${maxTablet}) {
      font-size: 10px;
      line-height: 14px;
    }
  }
  .person-company-here {
    @media all and (max-width: ${maxTablet}) {
      font-size: 10px;
      line-height: 10px;
      margin-top: 2px !important;
      margin-bottom: 2px;
    }
  }
  .company-name-two {
    margin-top: 0px;
  }
  .persona-type{
    border-radius: 15px;
    color:#fff;
    font-size:14px;
    font-family: ${secondaryFont};
    font-weight:${wLight};
    background: linear-gradient(${(props) => props.color});
    padding:3px 7px;
    margin-top:-25px;
    position: relative;
    z-index: 1;
    min-width:120px;
    text-align:center;
    svg{
      margin-left: 5px;
      vertical-align: middle;
    }
  }
  &.btnAdd {
    .follow-c-btn {
      margin-bottom: 40px;
    }
  }
  &.removeActive, &.checkActive {
     ${blueCheckBoxStyle}
    &:before {
      content: '';
      height: 100%;
      width:  100%;
      position: absolute;
      opacity: 0.5;
      background-color: #f9f8f6;
      z-index: 1;
    }
    .ant-checkbox,.remove-btn {
      z-index: 2;
    }
    .drawerAction {
      display: none;
    }
  }
`;

const PeopleCard = function PeopleCard(props: PersonProps) {
  const navigate = useNavigate();
  const followFailed = useSelector(selectFollowFailed);
  const followLoading = useSelector(selectFollowLoading);
  const [disableButton, setDisableButton] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const host = window.location.href;

  const {
    persona,
    logo,
    personName,
    tag,
    follow,
    noFollow,
    personaType,
    hidePersonality,
    wrapperColor,
    showCheckBox,
    showRemoveIcon,
    personId,
    personality,
    personAddedFlag,
    diameter,
    diameterSm,
    diameterXxsm,
    imageDiameter,
    isBtnAdd,
    checked,
    dragCheck,
    company,
    addRemovePerson,
    openInNewTab,
    disableNavigation,
    tagTextPadding,
    isBoxResOne,
    personalityTypesColors,
    isClassFromLargeCard,
    holoDiameterHeight,
    holoDiameterWidth,
    holoDiameterXxsmHeight,
    holoDiameterXxsmWidth,
    showDrawerIcon,
    groupTitle,
    membersActive,
    isPersonPage,
    executiveType,
    isSearchPage,
    isLivePerson,
    isRecommended,
    inGlobalSearch,
    profileConfirmation,
  } = props;

  const url = profileConfirmation ? '' : `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=${personality}`;
  const isOnboarding = isOnboardingRoute();
  const data = useSelector(selectData);
  const startTime = getCurrentTime();

  useEffect(() => {
    setDisableButton(false);
  }, [followFailed]);

  useEffect(() => {
    if (!followLoading) {
      setDisableButton(false);
    }
  }, [followLoading]);

  const handleFollowPerson = () => {
    if (!personAddedFlag) {
      setDisableButton(true);
      const obj: UpdatePersonProfileFollow = {
        person_data: [{
          type: personality,
          query: personName,
          entity_id: personId,
        }],
      };
      const groupId = searchParams.get('groupId');
      if (pathname.includes(SEARCH) && groupId && parseInt(groupId, 10) > 0) {
        obj.group_id = parseInt(groupId, 10);
      }
      dispatch(peopleFollowActions.updateProfileFollow(obj));
      if (obj.person_data && obj.person_data.length > 0) {
        if (isOnboarding) {
          dispatch(flurryActions.callFlurryEvent(
            valueToObject('click_Follow_FollowPeople_Onboarding', startTime, {
              person_data: obj.person_data,
              host,
            }),
          ));
        }
      }
    } else {
      setDisableButton(true);
      const obj: UpdatePersonProfileFollow = {
        feedids: [personId],
        type: personality,
      };
      dispatch(peopleFollowActions.updateProfileFollow(obj));
      if (isOnboarding) {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('click_Unfollow_FollowPeople_Onboarding ', startTime, {
            feedids: obj.feedids,
            type: obj.type,
            host,
          }),
        ));
      }
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    e.stopPropagation();
    handleFollowPerson();
    if (follow && !pathname.includes('onboarding')) {
      dispatch(certificationActions.certificationAction({
        type: 'person_follow',
        source: 'search',
        data,
      }));
    }
    if (isSearchPage) {
      if (follow) {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('searchPage_person_follow_clicked', 0, {
            person_name: personName,
            person_id: personId,
            type: isLivePerson ? 'LIVESEARCH' : 'EXECUTIVE',
            host,
          }),
        ));
      } else {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('searchPage_person_unfollow_clicked', 0, {
            person_name: personName,
            person_id: personId,
            type: isLivePerson ? 'LIVESEARCH' : 'EXECUTIVE',
            host,

          }),
        ));
      }
    }
    if (isRecommended) {
      if (follow) {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('peopleDashboard_recommended_follow_person', 0, {
            person_id: personId,
            host,
          }),
        ));
      }
    }
  };

  const handleWidgetClick = () => {
    if (isRecommended && !!disableNavigation) {
      dispatch(personActions.setIsRecommended(true));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('peopleDashboard_viewRecommendedPerson_open', 0, {
          person_id: personId,
          host,
        }),
      ));
    }
  };

  const handleInfoClick = () => {
    navigate(url);
  };
  return (
    <Wrapper
      color={persona}
      diameter={diameter}
      diameterSm={diameterSm}
      tagTextPadding={tagTextPadding}
      className={classNames('person-item', {
        removeActive: showRemoveIcon,
        checkActive: showCheckBox,
        btnAdd: isBtnAdd,
        isDragItem: !dragCheck,
        inBoxResOne: isBoxResOne,
        classFromLargeCard: isClassFromLargeCard,
        searchPeopleCard: inGlobalSearch,
      })}
    >
      {!dragCheck && <div className="back-drag-check" />}
      <div
        className="person-item-inner-div d-flex justify-content-center flex-column align-items-center"
        onKeyPress={handleWidgetClick}
        role="button"
        tabIndex={0}
        onClick={handleWidgetClick}
        aria-label="button"

      >
        <PeopleWidget
          logo={logo}
          persona={persona}
          hidePersonality={hidePersonality}
          personality={personaType}
          wrapperColor={wrapperColor}
          showRemoveIcon={showRemoveIcon}
          showCheckBox={showCheckBox}
          checked={checked}
          addRemovePerson={addRemovePerson}
          personId={personId}
          diameter={imageDiameter}
          diameterXxsm={diameterXxsm}
          diameterXsm="120px"
          diameterSm="100%"
          holoDiameterHeight={holoDiameterHeight}
          holoDiameterWidth={holoDiameterWidth}
          holoDiameterXxsmHeight={holoDiameterXxsmHeight}
          holoDiameterXxsmWidth={holoDiameterXxsmWidth}
          ringPadding="0px"
          ringPaddingSm="0px"
          type={personality}
          openInNewTab={openInNewTab}
          disableNavigation={disableNavigation}
          personalityTypesColors={personalityTypesColors}
          fromLargeCard
          showDrawerIcon={showDrawerIcon}
          isPersonPage={isPersonPage}
          inGlobalSearch={inGlobalSearch}
        />
      </div>
      <div
        className="below-card-detail"
      >
        <span
          role="button"
          tabIndex={0}
          className={classNames('c-pointer', { disableButton: isOnboarding })}
          aria-label={`view ${personName} profile`}
          onClick={handleInfoClick}
          onKeyPress={handleInfoClick}
        >
          <div className="d-flex justify-content-center">
            <Tooltip
              trigger="hover"
              placement="top"
              getPopupContainer={(e: HTMLElement) => e}
              className="triggers-bullet-tooltip person-designation-tip"
              color="#fff"
              title={personName}
            >
              <h5 className="primary-color text-center text-truncate-one-line person-name-here">
                {personName}
              </h5>
            </Tooltip>
          </div>
          <Tooltip
            trigger="hover"
            placement="top"
            getPopupContainer={(e: HTMLElement) => e}
            className="triggers-bullet-tooltip person-designation-tip"
            color="#fff"
            title={tag}
            autoAdjustOverflow={false}
          >
            <p className={`text-center tag-p person-tag-here ${inGlobalSearch ? 'text-truncate-one-line' : 'text-truncate-two-line mb-0'}`}>
              {`${tag}`}
            </p>
          </Tooltip>
          <Tooltip
            trigger="hover"
            placement="top"
            getPopupContainer={(e: HTMLElement) => e}
            className="triggers-bullet-tooltip person-designation-tip"
            color="#fff"
            title={company}
          >
            <p className={`tag-p company-name-two text-center  w-100 person-company-here ${inGlobalSearch ? 'text-truncate' : 'text-truncate-one-line'}`}>
              {`${company}`}
            </p>
          </Tooltip>
        </span>

        {noFollow ? null : (
          <div className="d-flex justify-content-center follow-c-btn">
            <SmallButton
              title={follow ? 'Follow' : 'Unfollow'}
              variant={follow ? 'filled' : 'outlined'}
              color="#001760"
              padding={follow ? '7px 22px' : '7px 15px'}
              onButtonClick={handleClick}
              disabled={disableButton}
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

PeopleCard.defaultProps = defaultProps;
export default PeopleCard;
