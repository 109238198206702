/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { css } from "styled-components";

const rootPath = process.env.REACT_APP_AMAZON_ASSETS_URL;

// Regulations
export const max = "1921px";
export const med = "1441px";
export const min = "1025px";

// Media
export const minExtraLargeScreen = "1922px";
export const maxExtraLargeScreen = "2500px";
export const maxWidth = "1500px";
export const minStandardScreen = "1439.95px";
export const maxHeight = "1080px";
export const maxWidth1300 = "1300px";
export const maxWidth1170 = "1170px";
export const midWidthApp = "1200px";
export const minWidthApp = "1100px";
export const maxTablet = "1023px";
export const midTablet = "993px";
export const minTablet = "820px";
export const maxMobile = "767px";
export const midMobile = "575px";
export const smMobile = "480px";
export const smMobile400 = "400px";
export const xmMobile = "380px";

// export const retina = '(000)';
export const retina = "(-webkit-device-pixel-ratio: 1.25)";
export const retinaOne = "(-webkit-device-pixel-ratio: 1)";
// Fonts
export const primaryFont = "Roboto";
export const secondaryFont = "Roboto Slab";

export const wELight = "300";
export const wLight = "400";
export const wMedium = "500";
export const wMLight = "600";
export const wMBold = "700";
export const wBold = "900";

// Images
export const headingImage = "./assets/redLabel.png";
export const listImage = "./assets/list.png";
export const checkboxTick = `${rootPath}salesApp/images/CheckCircle.svg`;
export const eyePassword = `${rootPath}salesApp/images/eye.svg`;
export const eyePasswordCross = `${rootPath}salesApp/images/eye_cross.svg`;
export const backDropPersonality = `${rootPath}salesApp/images/backDrop.png`;
export const dummyDisc = `${rootPath}salesApp/images/dummyDisc.png`;
export const extendedSearchLoader = `${rootPath}salesApp/images/search.gif`;
export const discPlaceholder = `${rootPath}salesApp/images/discPlaceholder.png`;
export const MailBox = `${rootPath}salesApp/images/social-mail.png`;
export const faceBookIcon = `${rootPath}salesApp/images/social-fb.png`;
export const linkedinIcon = `${rootPath}salesApp/images/social-linkedin.png`;
export const bufferIcon = `${rootPath}salesApp/images/social-box.png`;
export const bitmapIcon = `${rootPath}salesApp/images/social-bitmap.png`;
export const twitterIcon = `${rootPath}salesApp/images/social-twitter.png`;
export const userPlaceHolder = `${rootPath}salesApp/images/personplaceholder.png`;
export const socialTeamsIcon = `${rootPath}salesApp/images/socialTeamsIcon.png`;
export const primaryWhiteLogo = `${rootPath}salesApp/images/primaryLogo.png`;
export const primaryGreenLogo = `${rootPath}salesApp/images/seconderyLogo.png`;
export const lRedBelt = `${rootPath}salesApp/beltImages/l-belt-red.svg`;
export const lBlackBelt = `${rootPath}salesApp/beltImages/l-belt-black.svg`;
export const lBlueBelt = `${rootPath}salesApp/beltImages/l-belt-blue.svg`;
export const lYellowBelt = `${rootPath}salesApp/beltImages/l-belt-yellow.svg`;
export const lGreenBelt = `${rootPath}salesApp/beltImages/l-belt-green.svg`;
export const lWhiteBelt = `${rootPath}salesApp/beltImages/l-belt-white.svg`;
export const cRedBelt = `${rootPath}salesApp/beltImages/cRedBelt.svg`;
export const cBlackBelt = `${rootPath}salesApp/beltImages/cBlackBelt.svg`;
export const cBlueBelt = `${rootPath}salesApp/beltImages/cBlueBelt.svg`;
export const cYellowBelt = `${rootPath}salesApp/beltImages/cYellowBelt.svg`;
export const cGreenBelt = `${rootPath}salesApp/beltImages/cGreenBelt.svg`;
export const cWhiteBelt = `${rootPath}salesApp/beltImages/cWhiteBelt.svg`;
export const highPriceStat = `${rootPath}salesApp/images/highPriceStats.png`;
export const lowPriceStat = `${rootPath}salesApp/images/lowPriceStats.png`;
export const wbSmartLayer = `${rootPath}salesApp/images/wbSmartLayer.png`;
export const wbSmartLaptop = `${rootPath}salesApp/images/wbSmartLaptop.png`;
export const appleStore = `${rootPath}salesApp/images/appleStore.png`;
export const googleStore = `${rootPath}salesApp/images/googleStore.png`;
export const xiqWhiteLogo = `${rootPath}salesApp/images/xiqWhiteLogo.png`;
export const calenderBookIcon = `${rootPath}salesApp/images/calendarBook.png`;
export const welcomeBackgroundLayer = `${rootPath}salesApp/images/welcomeBackgroundLayerPopup.png`;
export const xiQLoader = `${rootPath}salesApp/images/xiqSpinner.gif`;
export const gillRoyDesk = `${rootPath}salesApp/images/gillRoyDesk.png`;
export const youtubeIconPlay = `${rootPath}salesApp/images/youtubeIconPlay.png`;
export const joinSession = `${rootPath}salesApp/images/joinSession.png`;
export const sliderArrowIcon = `${rootPath}salesApp/images/rightSilderIcon.png`;
export const LimitReactBck = `${rootPath}salesApp/images/LimitReactBck.png`;
export const popupBgLayerV1 = `${rootPath}salesApp/images/popupBgLayerV1.png`;
export const dummySplash = `${rootPath}salesApp/images/dummySplash.png`;
export const welcomeTourBanner = `${rootPath}salesApp/images/salesTourStart.png`;
export const gillRoyTaskComplete = `${rootPath}salesApp/images/gillRoyTaskComplete.png`;
export const gillRoyCertificationComplete = `${rootPath}salesApp/images/gillRoyCertificationComplete.png`;
export const playTourVideo = `${rootPath}salesApp/images/playTourVideo.png`;
export const nextButtonSlider = `${rootPath}salesApp/images/nextButtonSlider.png`;
export const previousButtonSlider = `${rootPath}salesApp/images/previousButtonSlider.png`;
export const backgroundGreenLayer = `${rootPath}salesApp/images/backgroundGreenLayerV2.png`;
export const certificationBot = `${rootPath}salesApp/images/certificationBot.png`;
export const gillBotPoint = `${rootPath}salesApp/images/gill-bot-points.png`;
export const gillRoyPlusChatGPT = `${rootPath}salesApp/images/GillRoyBotIconV2.png`;
export const gillRoyTilled = `${rootPath}salesApp/images/gillRoyTilled.png`;
export const gillRoyOutlookIcon = `${rootPath}salesApp/images/gillRoyOutlookIcon.png`;
export const gillRoyAnimation = `${rootPath}salesApp/images/xiQEmailLoaderV4.gif`;
export const writerGptGif = `${rootPath}salesApp/images/writerGptGifV3.gif`;
export const chatBubble = `${rootPath}salesApp/images/deskBubbleGillV2.gif`;
export const chatBubbleV2 = `${rootPath}salesApp/images/deskBubbleGillV3.gif`;
export const chatBubbleV3 = `${rootPath}salesApp/xiQgptGlobal/chatBubbleV3.gif`;
export const hiBotToolTip = `${rootPath}salesApp/images/hiBotToolTip.png`;
export const gillCompanyComparison = `${rootPath}salesApp/images/companyComparisionGif.gif`;
export const companyComparisionState = `${rootPath}salesApp/images/companyComparisionState.gif`;
export const loadingDyanmicGpt = `${rootPath}salesApp/images/loadingDyanmicGptV1.gif`;
export const timeOutGillRoy = `${rootPath}salesApp/images/timeOutGillRoy.png`;
export const companyComparisonGilroy = `${rootPath}salesApp/images/companyComparisonGilroy.png`;
export const financialChartStatsAnimation = `${rootPath}salesApp/images/financialChartStatsAnimation.gif`;
export const smartAccountSplashScreen = `${rootPath}salesApp/images/Account_Plan_SplashImage.png`;
export const emptyDocumentHub = `${rootPath}salesApp/images/emptyDocumentHub.png`;
export const wordFileIcon = `${rootPath}salesApp/images/wordFileIcon.png`;
export const pdfFileIcon = `${rootPath}salesApp/images/pdfFileIcon.png`;
export const pptFileIcon = `${rootPath}salesApp/images/pptFileIcon.png`;
export const txtFileIcon = `${rootPath}salesApp/images/txtFileIcon.png`;
export const uploadDocumentLoader = `${rootPath}salesApp/images/uploadDocumentLoader.gif`;
export const alertArticlePlaceholder = `${rootPath}salesApp/images/alert-article-placeholder.png`;
export const gilRoyBannerCertification = `${rootPath}salesApp/images/gilRoyBannerCertification.png`;
export const gilroyV2ChatBot = `${rootPath}salesApp/images/gilroyV2ChatBot.png`;
export const xiqGPTGlobalHeaderImage = `${rootPath}salesApp/images/xiqGPTGlobalHeaderImage.png`;
export const AskGilroyV2Image = `${rootPath}salesApp/images/AskGilroyV2Image.png`;
export const AskGilroyV3Image = `${rootPath}salesApp/images/AskGilroyV3Image.png`;
export const AskGilroyV4Image = `${rootPath}salesApp/xiQgptGlobal/AskGilroyV4Image.png`;
export const searchIconGilroyImge = `${rootPath}salesApp/images/searchIconGilroyImge.png`;
export const sadGilroyFace = `${rootPath}salesApp/images/sadGilroyFace.png`;
export const xiqGPTGlobalHeaderWriterGif = `${rootPath}salesApp/images/xiqGPTGlobalHeaderWriterGif.gif`;
export const loadingGifV3 = `${rootPath}salesApp/images/loadingGifV3.gif`;
export const loadingGifGlobalSearch = `${rootPath}salesApp/images/searchingGptV1.gif`;
export const gillRoyPlusChatGPTv2 = `${`${process.env.PUBLIC_URL}/GillRoyBotIconV8-flow.gif`}`;
export const gilroyGeneratingImage = `${rootPath}salesApp/images/gilroy-generating-image.png`;
export const askGilroyImageV5 = `${rootPath}salesApp/xiQgptGlobal/askGilroyImageV5.png`;
export const GilroyNewFloat = `${rootPath}salesApp/xiQgptGlobal/GilroyNewFloat2.png`;
export const xiqGptGlobalHeaderImageV2 = `${rootPath}salesApp/xiQgptGlobal/xiqGptGlobalHeaderImageV2.png`;
export const hiBotTooltipV2 = `${rootPath}salesApp/xiQgptGlobal/hiBotTooltipV2.png`;
export const xiqGptGlobalWriterAnimation = `${rootPath}salesApp/xiQgptGlobal/xiqGptGlobalWriterAnimation.gif`;
export const askGilroyAnimation = `${`${process.env.PUBLIC_URL}/askGilroyAnimationV5.gif`}`;
export const askGilroyAnimationV6 = `${`${process.env.PUBLIC_URL}/askGilroyAnimationV6.gif`}`;
export const accountPlanGenerating = `${`${process.env.PUBLIC_URL}/accountPlanGenerating.gif`}`;
export const gilroyAnimations3 = `${`${process.env.PUBLIC_URL}/gilroy_Animations_3.gif`}`;
export const buyerGroupSAP = `${rootPath}salesApp/images/buyerGroupSAP.png`;
export const riskMitigationsSAP = `${rootPath}salesApp/images/riskMitigationsSAP.png`;
export const SWOTIconSAP = `${rootPath}salesApp/images/SWOTIconSAP.png`;
export const buyerAnalysisSAP = `${rootPath}salesApp/images/buyerAnalysisSAP.png`;
export const solutionIconSAP = `${rootPath}salesApp/images/solutionIconSAP.png`;
export const businessCaseSAP = `${rootPath}salesApp/images/businessCaseSAP.png`;
export const challengesPainpointsSAP = `${rootPath}salesApp/images/challengesPainpointsSAP.png`;
export const growthOppertunitiesSAP = `${rootPath}salesApp/images/growthOppertunitiesSAP.png`;
export const financialSummarySAP = `${rootPath}salesApp/images/financialSummarySAP.png`;
export const businessSegmentSAP = `${rootPath}salesApp/images/businessSegmentSAP.png`;
export const businessRiskSAP = `${rootPath}salesApp/images/businessRiskSAP.png`;
export const accountOverviewSAP = `${rootPath}salesApp/images/accountOverviewSAP.png`;
export const competitorAnalysisSAP = `${rootPath}salesApp/images/competitorAnalysisSAP.png`;
export const hiGilroyHeaderGif = `${rootPath}salesApp/images/hiGilroyHeaderGif.gif`;
export const hiGilroyHeaderGifv2 = `${rootPath}salesApp/images/hiGilroyHeaderGifv2.gif`;
export const hiGilroyHeaderGifv2Tablet = `${rootPath}salesApp/images/hiGilroyHeaderGifv2Tablet.gif`;
export const hiGilroyHeaderGifv2WebRes = `${rootPath}salesApp/images/hiGilroyHeaderGifv2WebRes.gif`;
export const englishFlagImage = `${rootPath}salesApp/images/english-flag-image.png`;
export const koreanFlagImage = `${rootPath}salesApp/images/korean-flag-image.png`;
export const dominantBanner = `${rootPath}salesApp/images/dominantBannerV1.png`;
export const conscientiousBanner = `${rootPath}salesApp/images/conscientiousBannerV1.png`;
export const steadyBanner = `${rootPath}salesApp/images/steadyBannerV1.png`;
export const influencingBanner = `${rootPath}salesApp/images/influencingBannerV1.png`;
export const dominantPopup = `${rootPath}salesApp/images/dominantPopup.png`;
export const conscientiousPopup = `${rootPath}salesApp/images/conscientiousPopup.png`;
export const steadyPopup = `${rootPath}salesApp/images/steadyPopup.png`;
export const influencingPopup = `${rootPath}salesApp/images/influencingPopup.png`;
export const groupListIcon = `${rootPath}salesApp/images/groupListPeopleIcon.png`;
// Colors
export const white = "#ffffff";
export const primaryBgColor = "rgb(249,248,246)";
export const SecondaryBgColor = "rgb(255,255,255)";
export const primaryColor = "#1EC252";
export const SecondaryColor = "#001760";
export const boxShadow = "0px 0px 24px rgba(0, 0, 0, 0.102473)";
export const primaryHeadingColor = "#424B50";
export const appBgColor = "#f9f8f6";
export const SecondaryColorTwo = "#5E7079";
export const favouriteArticleColor = "#1EC252";
export const whiteColor = "#B4B4B4";
export const greyColor = "#7E7E7E";
export const greyColorSecondary = "#667982";
export const textBlack = "#0D0D0D";
export const nutenixColor = '#8d8585';
export const nutenixGradientColor = 'rgb(141, 133, 133) 50%, rgb(141, 133, 133) 100%';

// Arc TypesColors
export const persuader = "#FA8C16";
export const motivator = "#FAAD14";
export const promoter = "#D48806";
export const collaborator = "#D4B106";
export const advocate = "#7CB305";
export const proponent = "#52C41A";
export const coordinator = "#389E0D";
export const equalizer = "#08979C";
export const manager = "#13C2C2";
export const strategist = "#1890FF";
export const challenger = "#096DD9";
export const investigator = "#722ED1";
export const designer = "#EB2F96";
export const leader = "#CF1322";
export const driver = "#F5222D";
export const creator = "#FA541C";

// Terms & Conditions
export const TermsAndConditions =
  "https://www.xiqinc.com/wp-content/docs/terms-of-use.html";

export const scrollbar = css`
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #001760;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #cfdae8;
  }
  & {
    scrollbar-face-color: #001760;
    border-radius: 12px;
  }
`;
export const scrollbarWhiteTrack = css`
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #001760;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }
  & {
    scrollbar-face-color: #001760;
    border-radius: 12px;
  }
`;

export const scrollbarX = css`
  overflow-y: hidden;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #001760;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #cfdae8;
  }
  & {
    scrollbar-face-color: #001760;
    border-radius: 6px;
  }
`;

export const ButtonPrimaryDefault = css`
  font-family: ${primaryFont};
  color: #fff;
  font-size: 18px;
  font-weight: ${wMedium};
  background-color: ${primaryColor};
  padding: 6px 20px;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
`;

export const ButtonSecondaryDefault = css`
  font-family: ${primaryFont};
  color: ${primaryColor};
  font-size: 18px;
  font-weight: ${wMedium};
  background-color: transparent;
  padding: 5px 20px;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  border: 1px solid ${primaryColor};
  margin-left: 10px;
`;

export const SmartAccountTitleText = css`
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  font-size: 18px;
  line-height: 20px;
  color: #5e7079;
  @media all and (max-width: ${maxWidth}), ${retina} {
    font-size: 14px;
  }
`;

export const SmartAccountHeadingText = css`
  font-family: ${secondaryFont};
  font-weight: ${wMBold};
  font-size: 32px;
  line-height: 40px;
  color: ${primaryHeadingColor};
  letter-spacing: -0.8px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const scrollbarTransparent = css`
  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    border-radius: 0px;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
  & {
    scrollbar-face-color: transparent;
    border-radius: 0px;
  }
`;

export const smartSliderControls = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  draggable: false,
  touchMove: false,
  swipeToSlide: false,
  swipe: false,
};

export const digestSliderControls = {
  dots: false,
  infinite: false,
  slidesToShow: 4.7,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 1.8,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2.8,
      },
    },
    {
      breakpoint: 1460,
      settings: {
        slidesToShow: 3.8,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4.7,
      },
    },
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 5.1,
      },
    },
    {
      breakpoint: 2200,
      settings: {
        slidesToShow: 5.7,
      },
    },
  ],
};

export const textTruncateSnippet = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;
export const textTruncateSnippetFour = css`
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
`;

export const peopleCardLargeMinImage = css`
  width: 120px;
  height: 120px;
  min-width: 120px;
`;

export const peopleCardLargeMinHolo = css`
  width: 115px;
  height: 105px;
  margin: 1.5px;
`;

export const blueCheckBoxStyle = css`
  .ant-checkbox-checked {
    &:after {
      border: 2px solid ${SecondaryColor};
    }
  }
  .ant-checkbox-inner {
    border: 2px solid ${SecondaryColor};
  }
  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${SecondaryColor};
      }
    }
    + {
      .ant-checkbox-wrapper {
        margin-left: 0px;
      }
    }
  }
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${SecondaryColor};
      }
    }
  }
  .ant-checkbox-input {
    &:focus {
      + {
        .ant-checkbox-inner {
          border-color: ${SecondaryColor};
        }
      }
    }
  }
`;

export const tabHeading = css`
  padding: 8px 2px;
  font-family: ${secondaryFont};
  font-weight: ${wMBold};
  font-size: 26px;
  line-height: 47px;
  color: ${primaryHeadingColor};
  letter-spacing: -0.65px;
  @media all and (max-width: ${maxMobile}) {
    font-size: 20px;
  }
`;
export const inputGlobal = css`
  border-radius: 23px;
  border: 1px solid #dadada;
  font-family: ${primaryFont};
  font-size: 14px;
  font-weight: ${wMedium};
  line-height: 13.592px;
  letter-spacing: -0.294px;
  border: 1px solid #dadada;
  color: ${primaryHeadingColor};
  &::placeholder {
    font-family: ${primaryFont};
    font-size: 14px;
    font-style: italic;
    font-weight: ${wLight};
    line-height: 13.592px;
    letter-spacing: -0.294px;
    color: #c1c1c1;
  }
  &:hover,
  :focus {
    border-color: ${primaryColor};
    box-shadow: 0 0 2px ${primaryColor};
  }
`;
export const inputRenameGlobal = css`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  border: none;
  font-family: ${primaryFont};
  font-size: 16px;
  font-weight: ${wMedium};
  line-height: 13.592px;
  letter-spacing: -0.294px;
  color: rgba(0, 0, 0);
  padding: 4px 60px 3px 11px;
  &::placeholder {
    font-family: ${primaryFont};
    font-size: 14px;
    font-style: italic;
    font-weight: ${wLight};
    line-height: 13.592px;
    letter-spacing: -0.294px;
    color: #c1c1c1;
  }
  &:hover,
  :focus {
    border-color: none;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const glassBackDrop = css`
  background-color: "rgba(255, 255, 255, 0.6)";
  backdrop-filter: "blur(10px)";
`;

export const uploadDocumentHoverStyleSnippet = css`
  border-color: #7ddb9b !important;
  background: linear-gradient(
    rgba(30, 194, 82, 0.19) 0%,
    rgba(30, 194, 82, 0.043) 100%
  ) !important;
  .ant-upload {
    background: none !important;
  }
  .ant-upload-drag-icon {
    svg {
      path {
        fill: ${primaryColor};
      }
    }
    .textual-div {
      .icon-text {
        color: #7ddb9b;
      }
    }
  }
  .upload-document-footer {
    border-color: #7ddb9b;
    .icon-div {
      .ppt-icon-svg,
      .text-file-icon {
        path {
          &:last-child {
            fill: #f4f4f4;
          }
        }
      }
      svg {
        path {
          fill: #7ddb9b;
        }
      }
      .icon-title {
        color: #7ddb9b;
      }
    }
    .footer-text {
      color: #7ddb9b;
    }
  }
`;
